<template>
  <section class="fixed right-0 inset-y-0 pl-40 max-w-590 w-full">
    <header class="flex justify-start p-10 pl-8 bg-white" style="border-left: 1px solid rgb(204, 204, 204)">
      <span
        class="icon-close flex items-center pt-1 text-xl text-grey-300 hover:text-grey-400 cursor-pointer"
        @click="hideTooltip"
      />
    </header>
    <div class="w-full h-full flex flex-col bg-white shadow-xl" role="alert">
      <iframe
        class="clickup-embed clickup-dynamic-height"
        src="https://forms.clickup.com/f/22vp2-2129/SUBA1T1CQ2S07JBTG2"
        frameborder="0"
        onmousewheel=""
        width="100%"
        height="100%"
        style="background: transparent; border: 1px solid #ccc;"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ElSlideTooltip',

  computed: {
    ...mapState({
      content: state => state.slideTooltip.content
    }),

    messages () {
      if (Array.isArray(this.content)) {
        return this.content
      }

      return [this.content]
    }
  },

  methods: {
    ...mapActions(['hideTooltip'])
  }

}
</script>
